import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'abt-reset-password-success',
	templateUrl: 'reset-password-success.component.html',
	styleUrls: ['reset-password-success.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordSuccessComponent {
	constructor(
		private readonly route: ActivatedRoute,
		private readonly router: Router
	) {}

	signIn() {
		this.router.navigate(['/login'], {
			queryParams: {
				email: this.email,
			},
		});
	}

	get email() {
		return this.route.snapshot.queryParamMap.get('email');
	}
}
