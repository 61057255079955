import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { Observable, iif, of, switchMap } from 'rxjs';

const IgnoreURLS = ['https://maps.googleapis.com'] as const;

@Injectable()
export class AuthBearerInterceptor implements HttpInterceptor {
	private readonly HEADER_KEY = 'Authorization';

	constructor(private readonly auth: Auth) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const ignore = IgnoreURLS.some((url) => req.url.startsWith(url));
		if (!ignore) {
			return iif(
				() => !req.headers.has(this.HEADER_KEY),
				new Promise((resolve) => {
					if (this.auth.currentUser) {
						return this.auth.currentUser
							.getIdToken()
							.then((token) => {
								if (token != null) {
									req = req.clone({
										headers: req.headers.set(
											this.HEADER_KEY,
											`Bearer ${token}`
										),
									});
									resolve(req);
								}
							});
					}
					return resolve({});
				}),
				of(null)
			).pipe(switchMap(() => next.handle(req)));
		}
		return next.handle(req);
	}
}
