import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { defineCustomElements } from '@abbott/ui/loader';
import * as Sentry from '@sentry/angular';
import {
	applyPolyfills as applyContentViewerPolyfills,
	defineCustomElements as defineContentViewers,
} from '@teamhive/content-viewers/dist/loader';
import {
	applyPolyfills as applyPdfViewerPolyfills,
	defineCustomElements as definePdfViewer,
} from '@teamhive/pdf-viewer/dist/loader';

import { AbbottConfig } from '@abbott/ui';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

defineCustomElements(window);

applyPdfViewerPolyfills().then(() => {
	definePdfViewer(window);
});

applyContentViewerPolyfills().then(() => {
	defineContentViewers(window);
});

if (environment.production) {
	Sentry.init({
		dsn: environment.sentryDsn,
		integrations: [Sentry.browserTracingIntegration()],
		tracesSampleRate: 1.0,
		tracePropagationTargets: [
			'localhost',
			/^https:\/\/education\.cardiovascular\.abbott/,
			/^https:\/\/us-central1-abbott-clinician-portal\.cloudfunctions\.net/,
		],
	});

	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then((moduleRef) => {
		const applicationRef = moduleRef.injector.get(ApplicationRef);
		const appComponent = applicationRef.components[0];
		enableDebugTools(appComponent);
	})
	.catch((err) => console.error(err));

AbbottConfig.specialties = [
	...environment.specialties.map((value) => ({ text: value, value })),
];

AbbottConfig.roles = [
	...environment.roles.map((value) => ({ text: value, value })),
];
