<abt-ui-box-container>
	<img src="/assets/images/logo.svg" slot="logo" />
	<abt-ui-heading slot="link" small color="white">
		{{ 'app.name' | transloco | uppercase }}
	</abt-ui-heading>
	<abt-ui-heading large thin>
		{{ 'user.account-deactivated' | transloco | uppercase }}
	</abt-ui-heading>
	<abt-ui-label small>
		{{ 'user.admin-deactivated-email' | transloco: { email } }}
	</abt-ui-label>
	<div
		fxLayout="row"
		fxLayoutAlign="space-between center"
		class="top-margin-6"
	>
		<div class="support">
			<abt-ui-label type="label" uppercase xsmall color="medium-gray">
				{{ 'actions.contact-support' | transloco }}
			</abt-ui-label>
			<a [href]="mailToEmail" target="_blank">
				{{ supportEmail }}
			</a>
		</div>
		<button type="button" (click)="signOut()">
			{{ 'actions.sign-out' | transloco | uppercase }}
		</button>
	</div>
</abt-ui-box-container>

<abt-trustarc-banner></abt-trustarc-banner>
