<abt-ui-box-container>
	<img src="/assets/images/logo.svg" slot="logo" />
	<abt-ui-heading slot="link" small color="white">
		{{ 'app.name' | transloco | uppercase }}
	</abt-ui-heading>
	<abt-ui-heading class="bottom-margin-1" large>
		{{ 'login.actions.forgot-password' | transloco | uppercase }}
	</abt-ui-heading>
	<abt-ui-label small color="dark-gray">
		{{ 'user.password-reset-link' | transloco: { email } }}
	</abt-ui-label>
</abt-ui-box-container>

<abt-trustarc-banner></abt-trustarc-banner>
