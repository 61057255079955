<abt-ui-box-container>
	<img src="/assets/images/logo.svg" slot="logo" />
	<abt-ui-heading slot="link" small color="white">
		{{ 'app.name' | transloco | uppercase }}
	</abt-ui-heading>

	<abt-ui-heading large>
		{{ 'login.header.title' | transloco | uppercase }}
	</abt-ui-heading>
	<abt-ui-label class="bottom-margin-3" small>
		{{ 'login.messages.automatically-redirected' | transloco }}
	</abt-ui-label>
	<abt-ui-alert class="bottom-margin-1">
		{{ 'login.alerts.attempting-sign-in' | transloco }}
	</abt-ui-alert>
</abt-ui-box-container>
