import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AccountDeactivatedComponent } from './account-deactivated/account-deactivated.component';
import { ForgotPasswordSentComponent } from './forgot-password-sent/forgot-password-sent.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordSuccessComponent } from './reset-password-success/reset-password-success.component';
import { SSOErrorComponent } from './sso-error/sso-error.component';
import { SSOSuccessComponent } from './sso-success/sso-success.component';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: 'authentication/success',
				component: SSOSuccessComponent,
			},
			{
				path: 'authentication/error',
				component: SSOErrorComponent,
			},
			{
				path: 'register',
				loadChildren: () =>
					import('./register/register.module').then(
						(m) => m.RegisterModule
					),
			},
			{
				path: 'logout',
				loadChildren: () =>
					import('./logout/logout.module').then(
						(m) => m.LogoutModule
					),
			},
			{
				path: 'login',
				loadChildren: () =>
					import('./login/login.module').then((m) => m.LoginModule),
			},
			{
				path: 'forgot-password-sent',
				component: ForgotPasswordSentComponent,
			},
			{
				path: 'reset-password/:resetToken',
				component: ResetPasswordComponent,
			},
			{
				path: 'reset-password-success',
				component: ResetPasswordSuccessComponent,
			},
			{
				path: 'account-deactivated',
				component: AccountDeactivatedComponent,
			},
		]),
	],
})
export class AuthRoutingModule {}
