<abt-ui-box-container>
	<img src="/assets/images/logo.svg" slot="logo" />
	<abt-ui-heading slot="link" small color="white">
		{{ 'app.name' | transloco | uppercase }}
	</abt-ui-heading>

	<abt-ui-heading large>
		{{ 'user.reset-password' | transloco | uppercase }}
	</abt-ui-heading>
	<abt-ui-label *ngIf="user" small color="dark-gray">
		{{
			'user.create-new-password-email' | transloco: { email: user.email }
		}}
	</abt-ui-label>

	<abt-ui-alert [hidden]="!error" color="danger">
		{{ 'user.invalid-password-token' | transloco }}
	</abt-ui-alert>

	<form abtForm [formGroup]="form" (ngSubmit)="resetPassword()">
		<abt-ui-label
			class="top-margin-3"
			type="label"
			uppercase
			xsmall
			color="medium-gray"
		>
			{{ 'user.new-password' | transloco | uppercase }}
		</abt-ui-label>
		<div class="input-container">
			<abt-ui-password-input
				[showLabel]="'actions.show' | transloco"
				[hideLabel]="'actions.hide' | transloco"
				[validationMessages]="validationMessages$ | async"
			>
				<input
					type="password"
					name="password"
					formControlName="password"
				/>
			</abt-ui-password-input>
		</div>
		<div class="top-margin-4" fxLayout="row" fxLayoutAlign="end center">
			<button [busy]="busy" type="submit">
				{{ 'actions.next' | transloco }}
			</button>
		</div>
	</form>
</abt-ui-box-container>

<abt-trustarc-banner></abt-trustarc-banner>
