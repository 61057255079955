import {
	CanActivateViaAdminGuard,
	CanActivateViaApprovedGuard,
	CanActivateViaDeactivatedGuard,
	SetupAuthenticationGuard,
} from '@abbott/abbott-api-client';
import { NgModule } from '@angular/core';
import { AuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import {
	ActivatedRouteSnapshot,
	RouterModule,
	RouterStateSnapshot,
	Routes,
} from '@angular/router';

import { TranslationsResolve } from '@abbott/frontend/common/translate';
import { AuthModule } from './pages/auth/auth.module';
import {
	AdminPageTemplateComponent,
	ClinicianPageTemplateComponent,
	DefaultRouteComponent,
} from './shared/components';
import { DefaultRouteGuard } from './shared/guards';

const routes: Routes = [
	{
		path: '',
		resolve: {
			translations: TranslationsResolve,
		},
		canActivate: [AuthGuard],
		canActivateChild: [SetupAuthenticationGuard],
		data: {
			authGuardPipe: (
				_r: ActivatedRouteSnapshot,
				{ url }: RouterStateSnapshot
			) =>
				redirectUnauthorizedTo(
					`login?redirectUrl=${encodeURIComponent(url)}`
				),
		},
		children: [
			{
				path: '',
				pathMatch: 'full',
				canActivate: [DefaultRouteGuard],
				component: DefaultRouteComponent,
			},
			{
				path: 'account-setup',
				loadChildren: () =>
					import(
						'./pages/auth/account-setup/account-setup.module'
					).then((m) => m.AccountSetupModule),
			},
			{
				path: 'clinician',
				component: ClinicianPageTemplateComponent,
				canActivate: [
					CanActivateViaApprovedGuard,
					CanActivateViaDeactivatedGuard,
				],
				loadChildren: () =>
					import('./pages/clinician/clinician.module').then(
						(m) => m.ClinicianModule
					),
			},
			{
				path: 'admin',
				component: AdminPageTemplateComponent,
				canActivate: [
					CanActivateViaAdminGuard,
					CanActivateViaDeactivatedGuard,
				],
				data: { breadcrumb: { skip: true } },
				children: [
					{
						path: 'profile',
						loadChildren: () =>
							import('./pages/profile/profile.module').then(
								(m) => m.ProfileModule
							),
					},
					{
						path: 'activities',
						loadChildren: () =>
							import('@abbott/frontend/activity').then(
								(m) => m.AdminActivityModule
							),
					},
					{
						path: 'ceus',
						loadChildren: () =>
							import('@abbott/frontend/activity').then(
								(m) => m.AdminContinuingEducationModule
							),
					},
					{
						path: 'users',
						loadChildren: () =>
							import('./pages/user-admin/user-admin.module').then(
								(m) => m.UserAdminModule
							),
					},
					{
						path: 'media',
						loadChildren: () =>
							import('@abbott/frontend/media').then(
								(m) => m.MediaAdminModule
							),
					},
					{
						path: 'organizations',
						loadChildren: () =>
							import('@abbott/frontend/organization').then(
								(m) => m.AdminOrganizationModule
							),
					},
					{
						path: 'categories',
						loadChildren: () =>
							import('@abbott/frontend/category').then(
								(m) => m.AdminCategoryModule
							),
					},
					{
						path: 'therapies',
						loadChildren: () =>
							import(
								'./pages/group-admin/group-admin.module'
							).then((m) => m.GroupAdminModule),
					},
					{
						path: 'reports',
						loadChildren: () =>
							import(
								'./pages/report-admin/report-admin.module'
							).then((m) => m.ReportAdminModule),
					},
					{
						path: 'notifications',
						loadChildren: () =>
							import('@abbott/frontend/notification').then(
								(m) => m.NotificationModule
							),
					},
					{
						path: 'settings',
						loadChildren: () =>
							import('@abbott/frontend/config').then(
								(m) => m.AdminConfigModule
							),
					},
					{
						path: '',
						pathMatch: 'full',
						redirectTo: 'activities',
					},
				],
			},
		],
	},
	{
		path: '**',
		pathMatch: 'full',
		redirectTo: '/clinician',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {}), AuthModule],
	exports: [RouterModule],
})
export class AppRoutingModule {}
