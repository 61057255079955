<abt-ui-box-container>
	<img src="/assets/images/logo.svg" slot="logo" />
	<abt-ui-heading slot="link" small color="white">
		{{ 'app.name' | transloco | uppercase }}
	</abt-ui-heading>
	<abt-ui-heading large>
		{{ 'user.password-reset-success' | transloco | uppercase }}
	</abt-ui-heading>
	<abt-ui-label small color="dark-gray">
		{{ 'user.password-reset-success-email' | transloco: { email } }}
	</abt-ui-label>

	<div
		class="top-margin-6 bottom-margin-4"
		fxLayout="row"
		fxLayoutAlign="end center"
	>
		<button type="button" (click)="signIn()">
			{{ 'user.actions.go-to-sign-in' | transloco }}
		</button>
	</div>
</abt-ui-box-container>

<abt-trustarc-banner></abt-trustarc-banner>
