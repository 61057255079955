import { UserService } from '@abbott/abbott-api-client';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../../../environments/environment';

@Component({
	selector: 'abt-account-deactivated',
	templateUrl: 'account-deactivated.component.html',
	styleUrls: ['account-deactivated.component.scss'],
})
export class AccountDeactivatedComponent implements OnInit {
	/**
	 * Email of the account that was deactivated
	 */
	email: string;

	public readonly supportEmail = environment.division.supportEmail;
	public readonly mailToEmail = `mailto:${this.supportEmail}`;

	constructor(
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly userService: UserService
	) {}

	async ngOnInit() {
		this.email = this.route.snapshot.queryParamMap.get('email')?.trim();
		if (!this.email && this.userService.authUser) {
			this.email = this.userService.authUser.email;
		}
	}

	signOut() {
		this.router.navigate(['/logout']);
	}
}
