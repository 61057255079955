import { UserService } from '@abbott/abbott-api-client';
import { User } from '@abbott/types/user';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { combineLatest, delay, startWith } from 'rxjs';

@Component({
	selector: 'abt-reset-password',
	templateUrl: 'reset-password.component.html',
	styleUrls: ['reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
	form = this.fb.group({
		password: [
			'',
			[
				Validators.required,
				Validators.minLength(8),
				Validators.pattern(
					/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-])[A-Za-z\d@$!%*#?&-]{8,}$/
				),
			],
		],
	});

	/**
	 * Fetches translations for the validation messages to be shown in the
	 * user password input component.
	 *
	 * Note: Was having issues with `instant` function so decided to use the `get`.
	 * Didn't dig too deep into the issue.
	 */
	readonly validationMessages$ = combineLatest(
		[
			this.translate.selectTranslate(
				'user.password-validations.one-number'
			),
			this.translate.selectTranslate(
				'user.password-validations.one-special-character',
				{
					characters: '#$%^&*{}:";\'<>-,~`!@',
				}
			),
			this.translate.selectTranslate(
				'user.password-validations.eight-character-minimum'
			),
		],
		(oneNumber, oneSpecialCharacter, eightCharacterMinimum) => ({
			oneNumber,
			oneSpecialCharacter,
			eightCharacterMinimum,
		})
	).pipe(
		startWith({
			oneNumber: 'user.password-validations.one-number',
			oneSpecialCharacter:
				'user.password-validations.one-special-character',
			eightCharacterMinimum:
				'user.password-validations.eight-character-minimum',
		}),
		// Needed to prevent "expression has changed after check" error
		delay(0)
	);

	busy: boolean;

	error: boolean;

	user: User;

	constructor(
		private readonly fb: UntypedFormBuilder,
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly translate: TranslocoService,
		private readonly userService: UserService
	) {}

	async ngOnInit() {
		// Sign any active user out of the application
		try {
			await this.userService.signOut();
		} catch {}
		const token = this.resetToken;
		try {
			const value: { exp: number } = JSON.parse(
				atob(token.split('.')[1])
			);
			if (new Date().getTime() / 1000 < value.exp) {
				await this.loadUser();
			}
		} catch (exception) {
			console.error('Failed to parse password reset token', exception);
		}
	}

	async resetPassword() {
		if (this.form.valid) {
			if (this.busy) {
				return;
			}
			this.busy = true;
			this.error = false;
			try {
				const resetSuccessful = await this.userService.resetPassword({
					passwordToken: this.resetToken,
					newPassword: this.form.get('password').value,
				});
				if (resetSuccessful) {
					this.userService.clearLoginActivity(this.user.email);
					this.router.navigate(['/reset-password-success'], {
						queryParams: {
							email: this.user.email,
						},
					});
				} else {
					this.error = true;
				}
			} catch (exception) {
				console.error('Error resetting user password.', exception);
			}
			this.busy = false;
		}
	}

	get resetToken() {
		return this.route.snapshot.paramMap.get('resetToken');
	}

	private async loadUser() {
		this.user = await this.userService.findUserWithPasswordToken(
			this.resetToken
		);
	}
}
