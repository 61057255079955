import {
    AlgoliaService,
    NotificationActivityService,
    UserService,
} from '@abbott/abbott-api-client';
import { DialogService } from '@abbott/frontend/common/cdk';
import { ToastService } from '@abbott/frontend/common/toast';
import { User } from '@abbott/types/user';
import {
    Component,
    ElementRef,
    HostBinding,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { differenceInSeconds } from 'date-fns/esm';
import { Subject, map, takeUntil, takeWhile, tap, timer } from 'rxjs';

import { Firestore, collection, doc, getDoc } from '@angular/fire/firestore';
import { environment } from './../environments/environment';
import { InactivityDialogComponent } from './shared/components';

@Component({
	selector: 'abt-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	@ViewChild('notificationController')
	private notificationController: ElementRef<HTMLAbtUiNotificationControllerElement>;

	/**
	 * `true` if the environment should be shown in the app.
	 * Should only be shown if the environment is not production.
	 */
	@HostBinding('class.has-banner')
	readonly showEnvironmentBanner =
		!environment.production || window.location.host.includes('localhost');
	/**
	 * The color of the environment banner that spans across the top of the app.
	 * We should display a danger color if the local user is in production for
	 * awareness. The banner will only show if the user is not in production or if
	 * they are running it locally, so the danger color banner will NOT show in a
	 * deployed production instance.
	 */
	readonly environmentBannerColor = environment.production
		? 'danger'
		: 'warning';
	/**
	 * The name of the environment the user is currently viewing
	 */
	readonly environmentName = environment.environment;
	/**
	 * The timestamp when the window had its focus lost by the user or some
	 * other source
	 */
	private blurStartAt: Date;
	/**
	 * Object containing all configuration values for the idle
	 * timeout and warning modal durations.
	 */
	private timeouts: InactivityTimeouts;
	/**
	 * `true` if the timeout dialog is currently open
	 */
	private timeoutDialogOpen = false;
	/**
	 * Subject emitted when the authentication state has changed for notification
	 * subscription
	 */
	private readonly authenticated$ = new Subject<boolean>();

	/**
	 * Returns the corresponding timeout minutes based on the users role and authentication state
	 */
	private get idleTimeoutMinutes() {
		const user = this.userService.authUser;
		return user
			? user.roles.includes('admin')
				? this.timeouts.adminIdleMinutes
				: this.timeouts.userIdleMinutes
			: null;
	}

	constructor(
		public readonly notificationActivityService: NotificationActivityService,
		public readonly userService: UserService,
		private readonly notification: ToastService,
		private readonly firestore: Firestore,
		private readonly dialogService: DialogService,
		private readonly router: Router,
		private readonly idle: Idle,
		readonly algoliaService: AlgoliaService
	) {
		algoliaService.init(environment.algolia);
	}

	async ngOnInit() {
		await this.fetchConfigs();
		this.userService.authUserChange$.subscribe(this.onAuthUserChange);
	}

	/**
	 * Dismisses the passed notification item
	 *
	 * @param event The notification item to be dismissed
	 */
	onNotificationDismiss(event: any) {
		this.notificationController.nativeElement.dismiss(event.detail);
	}

	/**
	 * Propagates event in notification service to refresh active table then dismiss notification
	 *
	 * @param event The notification to be dismissed after event is propagated
	 */
	onReload(event: any) {
		this.notification.reload$.next();
		this.onNotificationDismiss(event);
	}

	/**
	 * Fetches all the config data needed at this level
	 */
	private async fetchConfigs() {
		this.timeouts = await getDoc(
			doc(collection(this.firestore, 'config'), 'inactivityTimeouts')
		).then((doc) => doc.data() as InactivityTimeouts);
		this.setupIdleListener();
	}

	/**
	 * Sets up the idle library listeners based on the current authenticated
	 * users roles and configurations for the corresponding environment
	 */
	private setupIdleListener() {
		this.idle.setTimeout(this.timeouts.timeoutMinutes * 60);

		// Setup window events that cause the watchers to reset
		// TODO: evaluate if we can hook viewer playback actions into this stream
		this.idle.setInterrupts([...DEFAULT_INTERRUPTSOURCES]);

		// Subscribe to when the user becomes idle
		// We alert them and prompt them to continue
		this.idle.onIdleStart.subscribe(this.onIdleStart);

		// Subscribe to when the user times-out
		// We kick them back to the login page
		this.idle.onTimeout.subscribe(this.onTimeoutStart);

		// Set up the window events that are important to the function of the idler
		window.addEventListener('blur', this.onWindowBlur);
		window.addEventListener('focus', this.onWindowFocus);
	}

	/**
	 * Routes the user to the logout route due to their inactivity and stops the idle library
	 */
	private routeToLoginFromInactivity() {
		this.idle.stop();
		this.router.navigate(['/logout'], {
			state: {
				email: this.userService.authUser.email,
				inactive: true,
			},
		});
	}

	/**
	 * Called when the auth user changes in the system
	 *
	 * @param user The new auth user object
	 */
	private onAuthUserChange = (user: User) => {
		this.authenticated$.next(!!user);

		if (user != null) {
			this.notificationActivityService.notificationInterval$
				.pipe(takeUntil(this.authenticated$))
				.subscribe();

			const currentIdle = this.idle.getIdle();
			const newIdle = this.idleTimeoutMinutes * 60;

			if (currentIdle !== newIdle) {
				this.idle.setIdle(this.idleTimeoutMinutes * 60);
			}

			if (!this.idle.isRunning()) {
				// Restart the watchers if there is a user
				this.idle.watch();
			}
		} else {
			// Stop the watchers if there is no user
			this.idle.stop();
		}
	};

	/**
	 * Called when the idling of the idle library starts
	 * Idling is defined by when the timeout modal is open
	 */
	private onIdleStart = async () => {
		if (this.blurStartAt) {
			return;
		}

		const currentTime = new Date().getTime();
		const expireTime = this.timeouts.timeoutMinutes * 60 * 1000;
		const willExpireAt = new Date(currentTime + expireTime);

		// Create a countdown for the number of seconds until the modal
		let seconds = this.timeouts.timeoutMinutes * 60;
		const secondsRemaining$ = timer(0, 1000).pipe(
			takeWhile(() => seconds >= 0),
			map(() => seconds),
			tap(() => (seconds -= 1))
		);

		this.timeoutDialogOpen = true;
		const { role } = await this.dialogService.open({
			component: InactivityDialogComponent,
			componentProps: {
				willExpireAt,
				secondsRemaining$,
			},
		});

		this.timeoutDialogOpen = false;
		if (role === DialogService.roles.Confirm) {
			// Reset the idle watchers
			this.idle.watch();
		}
	};

	/**
	 * Called when the timeout of the idle library starts
	 * Timeout is defined by the time from when the timeout modal opens to
	 * when it is expected to close. The end of that time is the start of the timeout
	 */
	private onTimeoutStart = () => {
		this.dialogService.close();
		this.routeToLoginFromInactivity();
	};

	/**
	 * Called when the user removes focus from the window that this app is running in
	 */
	private onWindowBlur = () => {
		// If the user left the page while they were idling (i.e. while the
		// timeout warning modal was active), we'll take that as an action to
		// exit the app (simplification for background timeout behavior)
		if (this.timeoutDialogOpen) {
			this.routeToLoginFromInactivity();
			this.blurStartAt = null;
		} else {
			this.blurStartAt = new Date();
		}
	};

	/**
	 * Called when the user focuses on the window that this app is running in
	 */
	private onWindowFocus = () => {
		const currentTimestamp = new Date();

		if (
			this.blurStartAt &&
			this.idleTimeoutMinutes &&
			this.userService.authUser
		) {
			const hasExpired =
				differenceInSeconds(currentTimestamp, this.blurStartAt) / 60 >
				this.idleTimeoutMinutes;

			if (hasExpired) {
				// Make sure the user is sent to login (with param for alert message)
				this.routeToLoginFromInactivity();
			}
		}

		this.blurStartAt = null;
	};
}

interface InactivityTimeouts {
	adminIdleMinutes: number;
	userIdleMinutes: number;
	timeoutMinutes: number;
}
