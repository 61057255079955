import { UserService } from '@abbott/abbott-api-client';
import { DestroyService } from '@abbott/frontend/common/cdk';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
	selector: 'abt-sso-success',
	templateUrl: 'sso-success.component.html',
	styleUrls: ['sso-success.component.scss'],
	providers: [DestroyService],
})
export class SSOSuccessComponent implements OnInit {
	constructor(
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly userService: UserService,
		private readonly destroy$: DestroyService
	) {}

	async ngOnInit() {
		if (this.redirect == null) {
			this.loginSuccessHandler();
		}

		await this.signIn();

		if (this.redirect) {
			window.location.href = this.redirect;
		}
	}

	async signIn() {
		try {
			await this.userService.signInWithToken(this.token);
		} catch (exception) {
			console.error('Error signing in with SSO', exception);
		}
	}

	/**
	 * The custom Firebase auth token created by the
	 * authentication controller when processing the callback
	 * of an SSO user from their authentication provider.
	 */
	get token() {
		return this.route.snapshot.queryParamMap.get('token');
	}

	get redirect() {
		const candidate = this.route.snapshot.queryParamMap.get('redirect');
		return (candidate || '').trim().length === 0 ? null : candidate.trim();
	}

	private loginSuccessHandler() {
		this.userService.authUserChange$
			.pipe(
				takeUntil(this.destroy$),
				tap(() => {
					const authUser = this.userService.authUser;
					if (authUser) {
						if (authUser.roles.includes('admin')) {
							this.router.navigate(['/admin/activities']);
						} else {
							this.router.navigate(['/clinician/education']);
						}
					}
				})
			)
			.subscribe();
	}
}
