import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'abt-forgot-password-sent',
	templateUrl: 'forgot-password-sent.component.html',
	styleUrls: ['forgot-password-sent.component.scss'],
})
export class ForgotPasswordSentComponent {
	constructor(private readonly route: ActivatedRoute) {}

	get email() {
		return this.route.snapshot.queryParamMap.get('email');
	}
}
