<abt-ui-box-container>
	<img src="/assets/images/logo.svg" slot="logo" />
	<abt-ui-heading slot="link" small color="white">
		{{ 'app.name' | transloco | uppercase }}
	</abt-ui-heading>

	<abt-ui-heading large>
		{{ 'login.sso-error' | transloco | uppercase }}
	</abt-ui-heading>

	<abt-ui-alert class="top-margin-4" color="danger">
		{{ 'login.error.sign-in' | transloco }}
	</abt-ui-alert>

	<button class="top-margin-4 ghost small" [routerLink]="['/login']">
		{{ 'login.actions.back-to-sign-in' | transloco }}
	</button>
</abt-ui-box-container>
