import {
	AbbottApiClientModule,
	ALGOLIA_PREFIX,
	API,
	UserService,
} from '@abbott/abbott-api-client';
import { ENVIRONMENT } from '@abbott/frontend/common/cdk';
import { HttpModule } from '@abbott/frontend/common/http';
import {
	getSavedLanguageCode,
	getSupportedLangCode,
} from '@abbott/frontend/common/translate';
import {
	DATE_PIPE_DEFAULT_OPTIONS,
	DatePipeConfig,
	registerLocaleData,
} from '@angular/common';
import {
	HttpClientModule,
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import ja from '@angular/common/locales/ja';
import {
	APP_INITIALIZER,
	CUSTOM_ELEMENTS_SCHEMA,
	ErrorHandler,
	LOCALE_ID,
	NgModule,
} from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
	browserSessionPersistence,
	initializeAuth,
	provideAuth,
} from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@jsverse/transloco';
import { NgIdleModule } from '@ng-idle/core';
import * as Sentry from '@sentry/angular';
import { QuillModule } from 'ngx-quill';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared';
import { TranslocoRootModule } from './transloco-root.module';

registerLocaleData(ja);

@NgModule({
	declarations: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		NgxUiLoaderModule.forRoot({
			fgsColor: '#ffffff',
			fgsType: 'fading-circle',
			text: 'Please wait...',
		}),
		AbbottApiClientModule.forRoot([
			{
				provide: API,
				useValue: environment.webApi,
			},
			{
				provide: ENVIRONMENT,
				useValue: environment.environment,
			},
			{
				provide: ALGOLIA_PREFIX,
				// eslint-disable-next-line @typescript-eslint/dot-notation
				useValue: environment.algolia['prefix'],
			},
		]),
		SharedModule,
		IonicModule.forRoot(),
		TranslocoModule,
		QuillModule.forRoot({
			placeholder: '',
			modules: {
				toolbar: [
					['bold', 'italic', 'underline', 'strike'],
					['blockquote', 'code-block'],
					[
						{
							color: [
								'#FF4541',
								'#E65100',
								'#43A047',
								'#1C9BE6',
								'#6446EB',
								'#ACACC2',
								'#626272',
							],
						},
					],
					[{ list: 'ordered' }, { list: 'bullet' }],
					['clean'],
				],
			},
			formats: [
				'bold',
				'color',
				'code',
				'italic',
				'strike',
				'underline',
				'blockquote',
				'indent',
				'list',
				'code-block',
			],
		}),
		HammerModule,
		NgIdleModule.forRoot(),
		HttpModule,
		HttpClientModule,
		TranslocoRootModule,
	],
	providers: [
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		provideAuth(() =>
			initializeAuth(getApp(), { persistence: browserSessionPersistence })
		),
		provideAnalytics(() => getAnalytics()),
		provideFirestore(() => getFirestore()),
		provideStorage(() => getStorage()),
		{ provide: ENVIRONMENT, useValue: environment },
		{
			provide: APP_INITIALIZER,
			useFactory: (userService: UserService) => () => {
				// Make sure we have the correct language set prior to bootstrap,
				// otherwise, any `instant` translations won't display the correct translation
				const lang = getSavedLanguageCode();
				userService.applyApplicationLanguage(lang);
			},
			multi: true,
			deps: [UserService, Sentry.TraceService],
		},
		provideHttpClient(withInterceptorsFromDi()),
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler(),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: DATE_PIPE_DEFAULT_OPTIONS,
			useValue: {
				dateFormat: 'shortDate',
				timezone: 'utc',
			} as DatePipeConfig,
		},
		{
			provide: LOCALE_ID,
			// Figure out a way to make this dynamic, Angular Material does not like
			// extending String class so it cannot be used until Angular Material is removed
			useValue: getSupportedLangCode(getSavedLanguageCode()),
		},
	],
})
export class AppModule {}
