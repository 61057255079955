import { FormModule } from '@abbott/frontend/common/form';
import { TrustArcBannerComponentModule } from '@abbott/frontend/trustarc';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { AccountDeactivatedComponent } from './account-deactivated/account-deactivated.component';
import { AuthRoutingModule } from './auth-routing.module';
import { ForgotPasswordSentComponent } from './forgot-password-sent/forgot-password-sent.component';
import { ResetPasswordSuccessComponent } from './reset-password-success/reset-password-success.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SSOErrorComponent } from './sso-error/sso-error.component';
import { SSOSuccessComponent } from './sso-success/sso-success.component';

@NgModule({
	imports: [
		AuthRoutingModule,
		SharedModule,
		FormModule,
		TrustArcBannerComponentModule,
	],
	declarations: [
		ForgotPasswordSentComponent,
		ResetPasswordComponent,
		ResetPasswordSuccessComponent,
		AccountDeactivatedComponent,
		SSOSuccessComponent,
		SSOErrorComponent,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AuthModule {}
