import { provideTransloco, TranslocoModule } from '@jsverse/transloco';
import { NgModule } from '@angular/core';
import { TranslocoHttpLoader } from './transloco-loader';
import { environment } from '../environments/environment';

@NgModule({
	exports: [TranslocoModule],
	providers: [
		provideTransloco({
			config: {
				availableLangs: ['en', 'ja'],
				defaultLang: 'en',
				fallbackLang: 'en',
				// Remove this option if your application doesn't support changing language in runtime.
				reRenderOnLangChange: true,
				prodMode: environment.production,
				missingHandler: {
					useFallbackTranslation: true,
				},
			},
			loader: TranslocoHttpLoader,
		}),
	],
})
export class TranslocoRootModule {}
