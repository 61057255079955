<ng-container *ngIf="showEnvironmentBanner">
	<abt-ui-alert [color]="environmentBannerColor">
		<abt-ui-label type="label" xsmall>
			{{
				'app.current-environment' | transloco: { name: environmentName }
			}}
		</abt-ui-label>
	</abt-ui-alert>
</ng-container>

<router-outlet></router-outlet>

<abt-ui-notification-controller
	#notificationController
	(dismiss)="onNotificationDismiss($event)"
	(reload)="onReload($event)"
></abt-ui-notification-controller>

<ngx-ui-loader></ngx-ui-loader>
